<template>
  <main>
    <section class="container-xl intro-section mt-5">
      <div class="row justify-content-around">
        <div class="col-12 col-md-5">
          <div class="img-container shadow-lg mb-4 mb-md-0">
            <img :src="require('@/assets/img/' + product.src)" :alt="imageAltText" class="img-fluid">
          </div>
        </div>
        <div class="col-12 col-md-7">
          <div class="ms-md-4">
            <p>{{ product.description }}</p>
            <p class="mb-0 fw-bold">Available sizes:</p>
            <div class="my-3">
              <button
                  v-for="(dim, size) in product.sizes"
                  v-bind:key="dim"
                  class="sizes flex-lg-fill mb-2"
                  :class="size === sizeChosen ? 'active' : ''"
                  @click="chooseSize(size)">
                <span class="text-uppercase product-size">{{ size }}</span><br>
                <span class="product-dim">{{ dim }}</span>
              </button>
            </div>
            <div class="pricing my-5">
              <p class="price-retail">${{ getPrice($event, sizeChosen, 'retail') }}</p>
              <p class="price-wholesale"><sup>$</sup>{{ getPrice($event, sizeChosen, 'wholesale') }}<sup>*</sup></p>
              <p class="savings">You save: ${{ moneySaved }}</p>
              <p><small class="price-notice">*Prices are subject to change.</small></p>
            </div>
            <ul class="nav nav-details">
              <li class="nav-item">
                <button class="btn btn-detail active"
                        id="materials-tab"
                        href="#materials"
                        data-bs-toggle="pill"
                        data-bs-target="#materials"
                        role="tab"
                        aria-controls="materials"
                        aria-selected="true">
                  Materials
                </button>
              </li>
              <li class="nav-item" v-if="product.height">
                <button class="btn btn-detail"
                        id="height-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#height"
                        role="tab"
                        aria-controls="height"
                        aria-selected="true">Height
                </button>
              </li>
              <li class="nav-item" v-if="product.firmness">
                <button class="btn btn-detail"
                        id="firmness-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#firmness"
                        role="tab"
                        aria-controls="firmness"
                        aria-selected="true">Firmness
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-detail"
                        id="notes-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#notes"
                        role="tab"
                        aria-controls="notes"
                        aria-selected="true">Notes
                </button>
              </li>
            </ul>
            <div class="tab-content border-top py-5">
              <div class="tab-pane fade active show" id="materials" role="tabpanel"
                   aria-labelledby="materials-tab">
                <div v-for="(material, index) in product.materials" v-bind:key="index">
                  <h2 class="h5 material-title">{{ material.title }}</h2>
                  <p>{{ material.content }}</p>
                </div>
              </div>
              <div class="tab-pane fade" id="height" role="tabpanel" aria-labelledby="height-tab">
                <p>The <b>{{ product.title }}</b> mattress height is {{ product.height }}</p>
              </div>
              <div class="tab-pane fade" id="firmness" role="tabpanel" aria-labelledby="firmness-tab">
                <p>The <b>{{ product.title }}</b> mattress is available in:</p>
                <span
                    v-for="firmness in product.firmness"
                    v-bind:key="firmness"
                    class="border border-dark py-2 px-3 rounded-pill mx-2 d-inline-block">
                  {{ firmness }}
                </span>
              </div>
              <div class="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                <div v-if="product.notes.warranty">
                  <h2 class="h5">Warranty</h2>
                  <p>{{ product.notes.warranty }}</p>
                </div>
                <p v-if="product.notes['has-set']"><em>Available as a set</em></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'ModelDetails',
  props: ['product'],
  data () {
    return {
      sizeChosen: 'twin',
      moneySaved: ''
    }
  },
  computed: {
    imageAltText () {
      return this.product.title + ' mattress'
    },
  },
  methods: {
    chooseSize (size) {
      this.sizeChosen = size
      console.log(size, this.sizeChosen)
    },
    getPrice ($event, size, type) {
      let mattressPricing
      if (size === 'california king') {
        mattressPricing = this.product.pricing.find(pricing => pricing.name === 'california-king')
      }
      else if (size === 'twin xl') {
        mattressPricing = this.product.pricing.find(pricing => pricing.name === 'twin-xl')
      }
      else if (size === 'twin xl set') {
        mattressPricing = this.product.pricing.find(pricing => pricing.name === 'twin-xl-set')
      }
      else if (size === 'full xl') {
        mattressPricing = this.product.pricing.find(pricing => pricing.name === 'full-xl')
      } else {
        mattressPricing = this.product.pricing.find(pricing => pricing.name === this.sizeChosen)
      }
      this.moneySaved = mattressPricing['retail'] - mattressPricing['wholesale']
      console.log($event, size, type)
      return mattressPricing[type]
    }
    ,
  },
}
</script>

<style scoped lang="scss">
.sizes {
  background: transparent;
  border: 1px solid #17263a;
  border-radius: 1em;
  padding: 6px 10px;
  min-width: 7em;
  margin-right: .4em;
  transition: all 0.3s ease-out;

  &:last-child {
    margin-right: 0;
  }

  .product-dim {
    color: rgba(0, 0, 0, 0.6);
  }

  &.active,
  &.active .product-dim {
    background: #CD1619;
    color: white;
    border-color: transparent;
  }
}

.price {
  &-retail {
    margin-bottom: 0;
    text-decoration: line-through;
  }

  &-wholesale {
    color: #CD1619;
    font-size: 3em;
    margin: 0;
  }

  &-saved {
    color: #17263a;
    font-size: .85em;
  }
}

sup {
  top: -0.3em;
  font-size: 0.7em;
}

.btn.btn-detail {
  border: 1px solid transparent;
  border-radius: 0.25em 0.25em 0 0;
  margin-right: 0.4em;

  &:active, &.active,
  &:hover {
    background: #dee2e6;
  }

  &:focus {
    background: #f2f2f2;
    border-color: black;
    outline: 0;
    box-shadow: none;
  }

  &:last-child {
    margin-right: 0;
  }
}
.material-title {
  text-transform: capitalize;
}
</style>
