<template>
  <header class="banner position-relative">
    <div class="d-flex justify-content-center align-items-center mt-5 h-100">
      <div class="banner-container text-center  px-5">
        <h1 class="display-2">{{ title }}</h1>
        <p class="lead">{{ lead }}</p>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderDetails',
  props: ['title', 'lead'],
  computed: {

  },
}
</script>

<style scoped>
.banner {
  margin-top: 200px;
  margin-bottom: 70px;
  position: relative;

}

.banner-container {
  //background: white;
  //color: $blue !important;
  //position: absolute;
  //bottom: 0;
  //z-index: 1;
  //box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
</style>
